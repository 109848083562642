import React, { useEffect } from 'react';
import { Header, Button } from 'semantic-ui-react';
import { Popup } from 'react-mapbox-gl';
import { handleDynamicPricingLogInMapList } from 'utils/helpers';
import styles from './SpotInfo.module.css';

const SpotPopup = (props: any) => {
    const { spots, id, rentalType } = props;

    const spot = spots[id];

    const spotPageUrl = `${spot['webflow_page_url']}/?rentalType=${rentalType}`;

    useEffect(() => {
        handleDynamicPricingLogInMapList(spot, rentalType);
        // eslint-disable-next-line
    }, []);

    return (
        <Popup
            key={id}
            coordinates={[spot.address.longitude, spot.address.latitude]}
            offset={{ 'bottom-left': [12, -38], bottom: [0, -38], 'bottom-right': [-12, -38] }}
        >
            <Header as="h3" textAlign="center" content={spot.name} />
            <a target="_blank" rel="noopener noreferrer" href={spotPageUrl}>
                <Button className={styles.bookNowButton} content="See details" fluid />
            </a>
        </Popup>
    );
};

export default SpotPopup;
