import React from 'react';
import { Card, Label, Button } from 'semantic-ui-react';
import { getRating } from '../../utils/helpers';
import styles from './SpotInfo.module.css';

const SpotCardResult = (props: { spot: any; link: string; price: number; interval: string; rentalType: string }) => {
    const { spot, link, price, interval, rentalType } = props;
    const spotPageUrl = `${spot['webflow_page_url']}/?rentalType=${rentalType}` || link;

    return (
        <Card fluid className={styles.card}>
            <Card.Content>
                {/* Spot Name */}
                <Card.Header className={styles.cardHeader}>{spot.name}</Card.Header>
                {/* Address */}
                <Card.Meta className={styles.cardMeta}>
                    {spot.address.address_line1}, {spot.address.city}, {spot.address.state} {spot.address.zipcode}
                </Card.Meta>
                {/* Rating and Rental Type */}
                <Card.Description className={styles.cardDescription}>
                    <div className={styles.ratingAndType}>
                        <Label
                            icon="star"
                            content={getRating([7, 9])}
                            style={{ backgroundColor: 'white', color: 'black', fontSize: 16 }}
                        />
                        <span className={styles.rentalType}>
                            <i className="clock outline icon"></i> {interval}
                        </span>
                    </div>
                </Card.Description>
            </Card.Content>
            {/* Price and Book Now Button */}
            <Card.Content extra>
                <div className={styles.priceAndButtonWrapper}>
                    <span className={styles.price}>${(price / 100).toFixed(2)}</span>
                    <Button className={styles.bookNowButton} onClick={() => window.open(spotPageUrl, '_blank')}>
                        See Details
                    </Button>
                </div>
            </Card.Content>
        </Card>
    );
};

export default SpotCardResult;
